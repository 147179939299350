import styled from 'styled-components'

export const Footer = styled.footer`
  --font-color: ${(props) => props.fontColor};
  --background-color: ${(props) => props.backgroundColor};
  --border-color: ${(props) => props.borderColor};

  width: 100%;
  margin-bottom: 80px;
  display: flex;
  justify-content: center;
  color: var(--font-color);
  background-color: var(--background-color);
  font-family: var(--brand-font-family);
  font-style: normal;
  font-weight: var(--brand-font-weight-400);
  padding-bottom: 30px;

  .footer-container {
    width: 100%;
    padding: 40px 33px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .brand-logo-container {
    width: 140px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }

  .brand-logo {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .mobile-apps-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .mobile-app-image-conatainer {
    width: 146px;
    height: 52px;
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-app-image {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .website-sections-container {
    width: 100%;
    padding: 30px 0;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
  }

  .website-section-name {
    flex: 0 0 49%;
    text-align: left;
    margin-top: 20px;
    font-size: 12px;
    line-height: 100%;
    text-decoration: none;

    &:first-child,
    &:nth-child(2) {
      margin-top: 0;
    }
  }

  .copy-right-text {
    width: 100%;
    margin-top: 30px;
    text-align: center;
    font-size: 12px;
    line-height: 100%;
    color: var(--brand-primary-grey-color);
  }

  .terms-of-service-text {
    width: 100%;
    margin-top: 30px;
    text-align: center;
    font-size: 12px;
    line-height: 100%;
    color: var(--brand-primary-grey-color);
    text-decoration: none;
  }

  .social-media-icons-container {
    display: flex;
  }

  .social-icon-container {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-left: 33px;
    margin-top: 33px;

    &:first-child {
      margin-left: 0;
    }
  }

  .social-icon-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .dynamic-footer-container {
    width: 100%;
    margin-top: 22px;
    padding-top: 22px;
    border-top: 1px solid var(--border-color);
    text-align: left;

    .dynamic-footer-links-container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-items: start;
      color: var(--brand-primary-grey-color);
      font-size: 12px;
      margin-bottom: 22px;

      a {
        font-size: 12px;
        text-align: left;
        line-height: 100%;
        text-decoration: none;
        margin: 8px 0px 0px 0px;
      }

      div {
        margin: 8px 8px 0px 8px;
      }
    }

    .dynamic-footer-content-container {
      width: 100%;
      text-align: left;
      font-size: 12px;
      line-height: 100%;
      color: var(--brand-primary-grey-color);
      div {
        text-align: left;
        font-size: 12px;
        line-height: 150%;
        margin: 8px 0px;
        color: var(--brand-primary-grey-color);
      }
    }
  }

  @media screen and (min-width: 768px) {
    .footer-container {
      width: 360px;
    }
  }
`
